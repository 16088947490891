import { LANGUAGE_LABELS } from './constants'
import { FieldValues, Validate } from 'react-hook-form'

export type user = {
  name: string
}

export type AuthEventResponseType = {
  //https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_AdminListUserAuthEvents.html
  ChallengeResponses: [
    {
      ChallengeName: string
      ChallengeResponse: string
    }
  ]
  CreationDate: number
  EventContextData: {
    City: string
    Country: string
    DeviceName: string
    IpAddress: string
    Timezone: string
  }
  EventFeedback: {
    FeedbackDate: number
    FeedbackValue: string
    Provider: string
  }
  EventId: string
  EventResponse: string
  EventRisk: {
    CompromisedCredentialsDetected: boolean
    RiskDecision: string
    RiskLevel: string
  }
  EventType: string
}

export type UpdateState = {
  updated: any[]
  current: any[]
}

/*export enum AUDIT_AUTHOR_TYPES {
  TRIALSTAFF = 'TrialStaff',
  ADMIN = 'Admin',
  CLINICIAN = 'Clinician',
}*/
export enum AUDIT_TYPES {
  CREATE_CLINICIAN = 'CREATE_CLINICIAN',
  CREATE_TRIALSTAFF = 'CREATE_TRIALSTAFF',
  UPDATE_CLINICIAN = 'UPDATE_CLINICIAN',
  UPDATE_CLINICIAN_PII = 'UPDATE_CLINICIAN_PII',
  UPDATE_CLINICIAN_SITE_ACCESS = 'UPDATE_CLINICIAN_SITE_ACCESS',
  UPDATE_CLINICIAN_PROJECT_ACCESS = 'UPDATE_CLINICIAN_PROJECT_ACCESS',
  RETIRE_CLINICIAN = 'RETIRE_CLINICIAN',
  REACTIVATE_CLINICIAN = 'REACTIVATE_CLINICIAN',
  UPDATE_TRIALSTAFF = 'UPDATE_TRIALSTAFF',
  UPDATE_TRIALSTAFF_PII = 'UPDATE_TRIALSTAFF_PII',
  RETIRE_TRIALSTAFF = 'RETIRE_TRIALSTAFF',
  REACTIVATE_TRIALSTAFF = 'REACTIVATE_TRIALSTAFF',
  CREATE_PHASE = 'CREATE_PHASE',
  UPDATE_PHASE = 'UPDATE_PHASE',
  CREATE_SUBJECT = 'CREATE_SUBJECT',
  UPDATE_SUBJECT = 'UPDATE_SUBJECT',
  REQUEST_SUBJECT_PII = 'REQUEST_SUBJECT_PII',
  CREATE_SITE = 'CREATE_SITE',
  UPDATE_SITE = 'UPDATE_SITE',
  ACTIVATE_PROTOCOL = 'ACTIVATE_PROTOCOL',
  RETIRE_PROTOCOL = 'RETIRE_PROTOCOL',
  CLONE_PROTOCOL = 'CLONE_PROTOCOL',
  ACTIVATE_PROJECT = 'ACTIVATE_PROJECT',
  RETIRE_PROJECT = 'RETIRE_PROJECT',
  ARCHIVE_PROJECT = 'ARCHIVE_PROJECT',
  CREATE_PROJECT = 'CREATE_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  ADMIN_LOGOUT = 'ADMIN_LOGOUT',
  INVITE = 'INVITE',
  REINVITE = 'REINVITE',
}
/*export const PROTOCOL_JUSTIFICATIONS = {
  LANGUAGE_ADD: 'Add language',
  QUESTIONNAIRE_ADD: 'Add questionnaire',
  QUESTIONNAIRE_REMOVE: 'Remove questionnaire',
  QUESTIONNAIRE_UPDATE: 'Update questionnaire',
} as const*/
// export type ProtocolJustificationTypes = keyof typeof PROTOCOL_JUSTIFICATIONS

/*export enum PROJECT_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
  ARCHIVED = 'ARCHIVED',
}*/

export enum RESOURCE_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
}

export enum QUESTIONNAIRE_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
  // ARCHIVED = 'ARCHIVED',
}

export enum PROJECT_CONDITION_ENUM {
  ECZCEMA = 'Eczcema',
  ARTHRITIS = 'Arthritis',
  OSTEOARTHRITIS = 'Osteoarthritis',
  ALZHEIMER = 'Alzheimer',
  PSORIASIS = 'Psoriasis',
  ACNE = 'Acne',
  ACTINIC_KERATOSIS = 'Actinic keratosis',
}

export enum PERSONNEL_STATUS_ENUM {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
}

export enum PERSONNEL_TYPE_ENUM {
  TRIAL_STAFF = 'TRIAL_STAFF',
  CLINICIAN = 'CLINICIAN',
}

export enum TRIAL_STAFF_TYPE_ENUM {
  CTM = 'CTM',
  CRA = 'CRA',
  DM = 'DM',
}

/*export enum TRIAL_STAFF_DISPLAY_TITLES {
  CTM = 'Clinical Trial Manager',
  CRA = 'Clinical Trial Associate',
  DM = 'Data Manager',
}*/

// this is no longer valid - should read from here questionnaireCRUD - questionnaireTypes
export enum QUESTIONNAIRE_TYPE_ENUM {
  VISIT = 'VISIT',
  PHASE = 'PHASE',
  DRAFT = 'DRAFT',
}

export enum PHASE_TYPE_ENUM {
  IN_SCREENING = 'IN_SCREENING',
  IN_STUDY = 'IN_STUDY',
}

// CRUD component state
export enum CRUD_VIEW_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  VIEW = 'VIEW',
}

export type VIEW_TYPE = keyof typeof CRUD_VIEW_TYPE

export enum QUESTIONNAIRE_RECURRENCE_ENUM {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  NONE = 'NONE',
  CUSTOM = 'CUSTOM',
}

export enum HTTPMethod {
  UPDATE = 'UPDATE',
  PUT = 'PUT',
  POST = 'POST',
}

//
// Config objects for all existing Forms
//
export type PhoneFormConfigObject = {
  id: string
  type: 'PHONE'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  value: string | { phone: string; countryCode: string }
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}

export type TextFormConfigObject = {
  id: string
  type: 'TEXT'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  value: string
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}

export type TextFormConfigObjectTranslation = {
  id: string
  type: 'TEXT_TRANSLATE'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  value: string
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}

export type TextAreaFormConfigObject = {
  id: string
  type: 'TEXTAREA'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  value: string
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}
export type DynamicOptionsFormConfigObject = {
  id: string
  type: 'DYNAMIC_OPTIONS'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  value: any[]
  validation: { required: boolean }
  errorMsg: string
  errorMsgField: string
  errorMsgGeneral: string
  disabled: boolean
  stateType: VIEW_TYPE
}
export type MultipleInputFormConfigObject = {
  id: string
  type: 'INPUT_MULTIPLE'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  value: string
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}

export type DropdownFormConfigObject = {
  id: string
  type: string
  className?: string
  placeholder: string
  label: string
  prefix: string
  postfix: string
  defaultValue: string
  value: string
  options: any[]
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE | any
}

export type NumberFormConfigObject = {
  id: string
  type: 'NUMBER'
  className?: string
  placeholder: string
  label: string
  prefix: string
  postfix: string
  defaultValue: string
  value: string
  min: number
  max: number
  options: any[]
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}

export type SliderRangeFormConfigObject = {
  id: string
  type: 'SLIDER_RANGE'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  defaultValue: string
  value: string
  options: any[]
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}

export type AutoCompleteFormConfigObject = {
  id: string
  type: 'AUTOCOMPLETE'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  defaultValue: string
  value: string
  options: any[]
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}

export type SliderFormConfigObject = {
  id: string
  type: 'SLIDER'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  defaultValue: string
  value: string
  min: number
  max: number
  displayFactor: number
  options: any[]
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}

export type SelectMultipleFormConfigObject = {
  id: string
  type: 'SELECT_MULTIPLE'
  placeholder: string
  label: string
  prefix: string
  postfix: string
  defaultValue: string
  value: string
  options: any[]
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}

export type TimeFormConfigObject = {
  id: string
  type: 'TIME'
  outputFormat?: 'HH:mm' | 'minutes'
  className: string
  placeholder: string
  label: string
  prefix: string
  postfix: string
  defaultValue: string
  value: string
  options: any[]
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
}

export type DateFormConfigObject = {
  id: string
  type: 'DATE'
  className: string
  placeholder: string
  label: string
  prefix: string
  postfix: string
  defaultValue: string
  value: any
  options: any[]
  validation: { required: boolean }
  errorMsg: string
  disabled: boolean
  stateType: VIEW_TYPE
  format: string
}

export type WindowWidgetConfigObject = {
  id: string
  type: 'WINDOW'
  label: string
  postfix: string
  value: {
    startAt: { m: number; h: number } | null
    endAt: { m: number; h: number } | null
  }
  validation: { required: boolean; validate?: Validate<any, FieldValues> }
  errorMsg: string
}

export type ReminderTimeWidgetConfigObject = {
  id: string
  type: 'REMINDER_TIME'
  label: string
  placeholder: string
  postfix: string
  value: { m: number; h: number; duration: number }
  validation: { required: boolean; validate?: Validate<any, FieldValues> }
  errorMsg: string
}

export type Answer = {
  enrollmentId: string
  questionId: string
  value: string
}

export type Project = {
  _id: string
  active: boolean
  projectRef: string
  title: string
  condition: string
  age: [low: number, high: number]
  sites: string[]
  questionnaires: string[]
  languages: LanguageISOCode[]
  maxDistanceTravel: number
  maxDistance: number
}

export type SiteType = {
  _id: string
  title: string
  active: boolean
  address: string
  contactPerson: string
  createdAt: Date
  projectId: string
  siteNumber: number
  timeZone: string
  updatedAt: Date
}

// See https://www.loc.gov/standards/iso639-2/php/English_list.php for specification, add as needed
// Not sure if something built in is smarter for this though...
export type LanguageISOCode = 'en' | 'es' | 'da' | 'yue'
type LanguageLabel = (typeof LANGUAGE_LABELS)[keyof typeof LANGUAGE_LABELS]

export type LanguageOption = {
  value: LanguageISOCode
  label: LanguageLabel
}

export type SupportedCountriesType = {
  abbreviation: string
  countryCode: string
  name: string
}

export type ProjectConfigType = {
  countries: SupportedCountriesType[]
  languages: { name: string; isoCode: string; active: null }[]
  timezones: { value: string; label: string }[]
  bodyParts: [key: string]
  groupTypes: [key: string]
  questionnaireRecurrence: [key: string]
  questionTypes: QuestionValueLabelType[]
}

export type Questionnaire = {
  _id: string
  projectId: string
  active: boolean
  start: string
  end: string
  name: string
  questions: Question[]
  createdOn: Date
  lastModified: Date
}

export type UnitSet = 'imperial' | 'metric'

export enum QuestionsTypes {
  radio = 'radio',
  checkbox = 'checkbox',
  text = 'text',
  number = 'number',
  info = 'info',
  bmi = 'bmi',
  phonenumber = 'phonenumber',
  name = 'name',
  gender = 'gender',
  birthdate = 'birthdate',
  nprs5 = 'nprs5',
  nprs11pt5l = 'nprs11pt5l',
  nprs11pt2l = 'nprs11pt2l',
  yesno = 'yesno',
}

export type QuestionType = keyof typeof QuestionsTypes

export type QuestionValueLabelType = { value: QuestionType; label: string }

export interface Question {
  _id: string
  questionnaireId: string
  title: Translation
  body: Translation
  continuation: Translation
  type: QuestionType
  // lastModified: Date
  next: (string | null)[]
  // storage: string
  required: boolean
  externalStorageId?: string
}

export interface RadioQuestion extends Question {
  options: Translation[]
  includingOptions: number[]
  excludingOptions: number[]
}

export interface CheckboxQuestion extends Question {
  options: Translation[]
  includingOptions: number[]
  excludingOptions: number[]
}

export interface PhoneNumberQuestion extends Question {}

export interface NameQuestion extends Question {
  placeholder: Translation
  minLength: number
  maxLength: number
}

export interface TextQuestion extends Question {
  placeholder: Translation
  minLength: number
  maxLength: number
}

export interface GenderQuestion extends Question {
  options: Translation[]
  includingOptions: number[]
  excludingOptions: number[]
}

export interface BirthdateQuestion extends Question {
  includingAgeRanges: { from: number; to: number }[]
  excludingAgeRanges: { from: number; to: number }[]
}

export interface NumberQuestion extends Question {
  min: number
  max: number
  includingRanges: { from: number; to: number }[]
  excludingRanges: { from: number; to: number }[]
}

export interface InfoQuestion extends Question {
  image: string
}

export interface BmiQuestion extends Question {
  defaultDisplayUnit: UnitSet
  weightHeader: Translation
  heightHeader: Translation
  imperialUnitText: Translation
  metricUnitText: Translation
  includingRanges: { from: number; to: number }[]
  excludingRanges: { from: number; to: number }[]
}

export type Nprs11OptionType = {
  classification?: Translation
  intensity: Translation[]
}
export interface Nprs11Question extends Question {
  label: string
  options: Nprs11OptionType[]
}

export interface Nprs5Question extends Question {
  field: string
  label: string
  options: string[]
}

export type Phase = {
  _id: string
  originalId: string
  projectId: string
  title: string
  duration: number
  stableId: string
  type: PHASE_TYPE_ENUM
  order: number
  groups: any[]
  bodyParts: string[]
  status: RESOURCE_STATUS
  shortCompliance: number
  overallCompliance: number
}

export type Translation = { [key: string]: string }
