/**
 * Created by @author @Søren Tramm on 08/08/2023.
 * @Description Adjusted copy of FormTextField
 */

import { Input } from 'antd'
import React, { useEffect } from 'react'
import { FormLabel } from './FormLabel'
import { UseFormReturn } from 'react-hook-form'
import { LanguageISOCode, TextAreaFormConfigObject } from '../../Types'
import { LANGUAGE_LABELS } from '../../constants'

type Props = {
  formHook: UseFormReturn
  data: TextAreaFormConfigObject
  languages?: any[] | undefined
  currentLanguage?: LanguageISOCode | undefined
}

const { TextArea } = Input

export const FormTextAreaTranslation = ({ data, formHook, languages, currentLanguage }: Props) => {
  const {
    register,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = formHook

  const errorState = errors[data.id]
  const errorMessage = errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  useEffect(() => {
    // List pf field ids, by enabled language
    const fields = languages?.map((lang) => `${data.id}.${lang.isoCode}`) || []

    // Register fields
    fields.forEach((field) => {
      register(field, { ...data.validation })
    })

    // Define the unregister function outside the effect
    // const handleUnregister = () => {
    //   unregister(fields)
    // }

    // Unregister fields when the component unmounts
    // return handleUnregister
  }, [languages, data.id, register, data.validation])

  useEffect(() => {
    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [data.value, data.id, data.validation, register, setValue])

  useEffect(() => {
    // Unregister fields when the component unmounts
    const fields = languages?.map((lang) => `${data.id}.${lang.isoCode}`) || []
    return () => unregister(fields)
  }, [unregister, data.id, languages])

  const onChange = (e: any, lang: LanguageISOCode) => {
    const id = `${data.id}.${lang}`
    setValue(id, e.target.value)
  }

  const compileErrorMessage = (state, message) => {
    const langList = Object.keys(state)
      .map((key) => LANGUAGE_LABELS[key])
      .join(', ')
    return `${message}: ${langList}`
  }

  const currentValue = getValues(data.id) ? getValues(data.id) : data.value

  return (
    <div className="col-12 w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      {languages &&
        languages.map((lang) => {
          return (
            <div key={lang.isoCode} className={`${lang.isoCode !== currentLanguage && 'd-none'}`}>
              {/*<p className={'text-end'} style={{ color: 'rgba(0,0,0,.33)', width: '100%', paddingRight: '15px' }}>*/}
              {/*  {lang}*/}
              {/*</p>*/}
              <TextArea
                name={data.id}
                // value={currentValue && currentValue[lang]}
                defaultValue={typeof currentValue === 'object' ? currentValue[lang.isoCode] || '' : ''}
                onChange={(e) => onChange(e, lang.isoCode)}
                placeholder={lang.isoCode.toUpperCase() + ' - ' + data.placeholder}
                autoSize={{ minRows: 4, maxRows: 4 }}
                style={{ width: '100%' }}
                prefix={'ælk'}
              />
            </div>
          )
        })}
      {errorState ? <div className="p-small text-danger">{compileErrorMessage(errorState, errorMessage)}</div> : null}
    </div>
  )
}
