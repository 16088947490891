/**
 * Created by @author Søren Tramm on 15/08/2023.
 */
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { LanguageISOCode, Question, QuestionValueLabelType, VIEW_TYPE } from '../../../Types'
import { populateConfig } from '../../../util/populateConfig'
import { getFormType } from '../../../components/form/formFactory'
import { getCommonQuestionConfig } from './config/commonQuestionConfig'

export const QuestionCommon = ({
  data,
  formHook,
  viewType,
  languageCodes,
  currentLanguage,
  questionTypes,
}: {
  data: Question
  formHook: UseFormReturn
  viewType: VIEW_TYPE
  languageCodes: LanguageISOCode[]
  currentLanguage: LanguageISOCode
  questionTypes: QuestionValueLabelType[]
}) => {
  const config = getCommonQuestionConfig(questionTypes)
  const configObj = data ? populateConfig(config, data, viewType) : config.concat([])

  return (
    <div className="col-12 w-100 py-2">
      {configObj.map((item, index) => {
        return getFormType(item, index, formHook, languageCodes, currentLanguage)
      })}
    </div>
  )
}
